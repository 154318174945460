@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i|Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext');

/* Common elements */

body {
    margin: 0;
    font-family: Calibri, sans-serif;
    background-color: #282828;
}
.site {
    position: relative;
    width: 1240px;
    margin: 0 auto;
    font-size: 14px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background-color: white;
}

.site > header {
    position: fixed;
    top: 0;
    width: 1240px;
    padding: 0 105px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 2;
}

.site main {
    position: relative;
}

.site main section {
    margin-top: -40px;
    padding-top: 40px;
}
.site main section > .inner {
    position: relative;
}


.site main .article-date,
.site main .social-elements,
.site main .focim header {
    display: none;
}

.site main #view-section,
.site main .main-view {
    padding: 0 105px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.site main .main-view .content-view-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-right: 40px;
    /* min-width: 735px; */
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 735px;
}


.site main .main-view .img-container {
    /*-webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;*/
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    font-size: 0;
    /*width: 100%;*/
    display: block;
    position: relative;
}


.site main .article-lead {
    font-size: 21px;
    font-weight: 700;
    margin-top: 30px;
}
.site main .article-body {
    font-size: 19px;
    line-height: 1.2em;
}
.site main .main-view:not(.konferenciak) .article-body > *:not(:first-child) {
    display: none;
}


.btn {
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 35px 8px;
    margin: 40px 0;
}
.btn-gray, #about-section .btn {
    color: #f9e805;
    background-color: #b3b3b3;
    margin-bottom: 40px;
    margin-top: 25px;
}
#voluntary-section .btn {
    color: #f9e805;
    background-color: #b3b3b3;
    margin: 20px 0 40px;
}
.btn-black, #conferences-section .btn {
    color: #f9e805;
    background-color: #000000;
    margin: 20px 0 60px;
}
.btn-white,
#news-section .btn,
#events-section .btn{
    position: absolute;
    z-index: 1;
    left: 105px;
    bottom: 0;
    color: #000000;
    background-color: #ffffff;
/*    margin-bottom: 50px;*/
}
.btn:hover, .btn:focus {
    opacity: 0.85;
    -webkit-box-shadow: 0 0 0px 1px darkgrey;
            box-shadow: 0 0 0px 1px darkgrey;
}
#news-section .btn,
#events-section .btn {
    left: 8.5%;
}




#intro-section .ContentListPortlet .content-list-title,
ul.nav li.hidden,
.main-view .portlet-heading {
    display: none;
}


/* Portal.css reset */
.content-list-elements {
    border-bottom: unset;
    padding: unset;
}

/* Main */

#about-section,
#events-section,
#voluntary-section,
#funding_purchase-section{
    background-color: #f9e805;
}
#conferences-section {
    border-top: 44px solid #f9e805;
    margin-top: 0;
}

#about-section h1,
#news-section h1,
#events-section h1,
#conferences-section h1,
#gallery-section h1,
#voluntary-section h1,
#funding_purchase-section h1,
#contact-section h1 {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 27px;
    font-weight: 500;
    margin: 0;

    padding-top: 30px;
    text-transform: uppercase;
}
#about-section h1 {
    padding-top: 50px;
}
#voluntary-section h1 {
    padding-top: 15px;
}
#news-section h1{
    color: #fff;
    margin-left: -2%;
}

#events-section h1,
#funding_purchase-section h1{
    margin-left: -2%;
}
#gallery-section h1 {
    margin-bottom: 60px;
}
#contact-section h1 {
    margin-bottom: 35px;
    padding-top: 30px;
}

#about-section h1:after,
#news-section h1:after,
#events-section h1:after,
#conferences-section h1:after,
#gallery-section h1:after,
#voluntary-section h1:after,
#funding_purchase-section h1:after,
#contact-section h1:after {
    display: block;
    content: "";
    position: absolute;
    left: 2px;
    bottom: -5px;
    width: 114px;
    border-top: 2px solid #000;
}
#news-section h1:after{
    border-color: #fff;
}

#about-section .ContentViewPortlet {
    margin-bottom: 0;
}

.language-select {
    text-align: right;
    margin: 3px 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.language-select ul {
    display: block;
    padding: 0;
    margin: 0;
    font-size: 0;
    height: 16px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.language-select .active {
    display: none;
}
.language-select .inactive {
    display: inline-block;
    position: relative;
    width: 27px;
    height: 17px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.language-select .inactive a::before {
    content: "";
    display: inline-block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 23px;
    height: 16px;
    right: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.language-select .inactive.Magyar a::before {
    background-image: url(../images/hun.svg);
}
.language-select .inactive.English a::before {
    background-image: url(../images/eng.svg);
}
.language-select .inactive.Deutsch a::before {
    background-image: url(../images/ger.svg);
}



#header-section .DynamicMenuPortlet.header{
    margin: 0;
}

.navbar button, #migrated-var-link {
    display: none;
}
.navbar .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
}
.navbar-header, .navbar-collapse {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}

.navbar-collapse {
    max-height: 45px;
}


.navbar-default .navbar-brand {
    background: url(../images/gym_logo.svg) no-repeat 0 0 transparent;
    background-size: contain;
    width: 160px;
    height: 145px;
    text-indent: 9999px;
    display: block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;    
}
ul.nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
ul.nav li {
    display: inline-block;
    font-size: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.navbar-nav > li > a{
    display: block;
    padding: 12px 8px;
    background-color: #f9e805;
    color: #666666;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
ul.nav.navbar-nav > li.active > a,
ul.nav.navbar-nav > li > a:hover{
    background-color: #000;
    color: #FFF;
    text-decoration: none;
}

/*lenyíló menüpont*/
ul.nav.navbar-nav .submenu li{
    text-align: left;
    width: 100%;
}

ul.nav li.with-submenu,
ul.nav li.is-parent {
    position: relative;
    cursor: pointer;
}

ul.nav li.with-submenu > a::after,
ul.nav li.is-parent > a::after {
    content: '';
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" fill="black"><path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/></svg>');
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 8px;
    height: 8px;
    position: relative;
    transition: 0.3s ease filter;
}

ul.nav li.with-submenu > a:hover::after,
ul.nav li.is-parent > a:hover::after,
ul.nav li.with-submenu > a[aria-expanded="true"]::after,
ul.nav li.is-parent > a[aria-expanded="true"]::after{
    filter: invert(1);
}
    
ul.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 0;
    z-index: 2;
    width: 220px;
}
nav ul.submenu li a,
ul.submenu li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
    background-color: #f9e805;
    color: #666666;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s ease background-color, 0.3s ease color;
}
ul.submenu li a:hover{
    color: #ffffff;
    background-color: #000000;
}
ul.nav li.is-parent > a[aria-expanded="true"] + ul.submenu,
ul.nav li.with-submenu .submenu-button[aria-expanded="true"] + ul.submenu {
    display: block;
}

ul.nav li.with-submenu > a[aria-expanded="true"],
ul.nav li.is-parent > a[aria-expanded="true"]{
    color: #ffffff;
    background-color: #000000;
}

ul.nav li.with-submenu > a[aria-expanded="true"]::after,
ul.nav li.is-parent > a[aria-expanded="true"]::after{
    transform: rotate(180deg);
}

ul.submenu ul.submenu {
    position: relative;
}

nav ul.submenu ul.submenu li a,
ul.submenu ul.submenu li a {
    padding-left: 20px;
}
/*lenyíló menüpont vége*/


nav.top-nav-collapse {
    background-color: rgba(255,255,255,0.95);
    margin: 0 -105px;
    padding: 0 105px;
    height: 45px;
    /* z-index: -1; */
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0 0 20px -5px #000;
            box-shadow: 0 0 20px -5px #000;
}
.top-nav-collapse.LanguageSelectPortlet {
    height: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.top-nav-collapse .navbar-header {   
    /*align-self: center;*/
    display: flex;
    align-items: center;
}
.top-nav-collapse .navbar-brand {
    background-image: url(../images/minifigura.jpg);
    width: 175px;
    height: 45px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.top-nav-collapse.language-select {
    display: block;
    margin: 0;
    position: relative;
    top: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.top-nav-collapse.language-select .inactive {
    display: block;
    position: static;
    right: 0;
    margin-bottom: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.top-nav-collapse.language-select ul {
    position: absolute;
    right: 0;
    width: initial;
}
.top-nav-collapse ul.nav.navbar-nav > li > a {
    padding: 12px 7px 11px 7px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.top-nav-collapse ul.nav.navbar-nav > li:last-child > a {
    padding-right: 28px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}




.focim {
    display: block;
    position: relative;
    overflow-y: hidden;
}
.focim .img-container {
    padding-top: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 685px;
    max-height: 100%;
    font-size: 0;
}
.focim .img-container-bg-img,
.img-container .img-container-bg-img {
    background-repeat: no-repeat;
    height: 100%;
    min-width: 385px;
    width: 100%;
    background-size: contain;
}
.focim .img-container-bg-img {
    position: absolute;
    background-size: 100%;
    background-position-x: 30%; 
}

.focim article {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 42%;
}


.site main .focim .article-lead {
    display: block;
    font-family: Century Gothic;
    font-weight: 300;
    font-size: 24px;
    text-transform: uppercase;
}
.site main .focim .article-lead p {
    display: table;
    margin: 6px 0;
    line-height: 24px;
    background-color: #f9e805;
}
.site main .focim .article-body {
    display: block;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 50px;
}
.site main .focim .article-body p {
    margin: 0;
    line-height: 1.2em;
}



.social-block {
    position: absolute;
    top: 150px;
    right: 0;
    z-index: 1;
    max-width: 275px;
}
.social-block.main {
    right: 105px;
}

.social-block .content-list-elements {
    margin-bottom: 2px;
    text-align: right;
}
.social-block a:hover img,
.social-block a:focus img {
    -webkit-box-shadow: 0 0 5px 1px #777;
            box-shadow: 0 0 5px 1px #777;
    opacity: 1 !important;
}
.social-block .content-list-elements.circle a:hover img,
.social-block .content-list-elements.circle a:focus img { 
    -webkit-box-shadow: 0 0 5px 1px #aaa; 
            box-shadow: 0 0 5px 1px #aaa;
    border-radius: 50%;
}
.social-block .content-list-elements.left {
    float: right;
    margin-left: 3px;
}
.social-block .content-list-elements.right {
    float: right;
}
.social-block .content-list-elements.top {
    margin-top: 40px;
}
.social-block .content-list-elements.ermegyujtes {
    position: absolute;
    top: 53px;
    right: 105px;
}
.social-block .content-list-elements .img-thumbnail {
    max-width: 190px;
    max-height: 95px;
    opacity: 0.9;
}

/* Csak ha 1% banner is van a listában: */
/* Ha leszedjük, a "top" classt vissza kell tenni a 3 felső sorban levő bannerre */
.social-block .content-list-elements.banner .img-thumbnail {
    max-height: 100px;
}
.social-block .content-list-elements.circle {
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
}
.social-block .content-list-elements.top {
    clear: both;
    display: block;
}




#news-section .main-view,
#events-section .main-view,
#funding_purchase-section .main-view{
    position: relative;
    overflow: hidden;

}
#news-section .background-image-container,
#events-section .background-image-container,
#funding_purchase-section .background-image-container {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
}
#news-section .background-image-container div,
#events-section .background-image-container div,
#funding_purchase-section .background-image-container div {
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
}

#funding_purchase-section .background-image-container div {
    background-position: center;
}

#news-section .content-list-container,
#events-section .content-list-container,
#funding_purchase-section .content-list-container{
    z-index: 1;
    padding-top: 30px;
}

#news-section .content-list-elements,
#events-section .content-list-elements {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 23.5%;
    margin: 40px 5px 110px;
    background-color: #fff;
}

#view-news-section .content-list-elements,
#funding_purchase-section .content-list-elements {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 275px;
    margin: 40px 13px 110px;
    background-color: #fff;
}
#news-section .content-list-elements,
#events-section .content-list-elements,
#funding_purchase-section .content-list-elements{
    min-height: 380px;
    margin-top: 30px;
}

#view-news-section .content-list-elements .newsbanner,
#funding_purchase-section .content-list-elements .newsbanner{
    height: 100%;
    text-decoration: none;
}
#news-section .content-list-elements .newsbanner,
#events-section .content-list-elements .newsbanner,
#funding_purchase-section .content-list-elements .newsbanner{
    display: block;
    height: 100%;
    text-decoration: none;
}
#view-news-section .banner-text-container,
#news-section .banner-text-container,
#events-section .banner-text-container,
#funding_purchase-section .banner-text-container{
    padding: 20px 20px 50px 20px;
    word-break: break-word;
}

#view-news-section .banner-image-container,
#news-section .banner-image-container,
#events-section .banner-image-container,
#funding_purchase-section .banner-image-container{
    max-height: 250px;
    overflow: hidden;
}

#view-news-section .banner-text-container .list-title,
#news-section .banner-text-container .list-title,
#events-section .banner-text-container .list-title,
#funding_purchase-section .banner-text-container .list-title{
    display: block;
    margin: 0;
    font-family: Calibri, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}
#view-news-section .banner-text-container .list-title:hover,
#news-section .banner-text-container .list-title:hover,
#events-section .banner-text-container .list-title:hover,
#funding_purchase-section .banner-text-container .list-title:hover{
    text-decoration: underline;
}
#view-news-section .banner-text-container .list-lead,
#news-section .banner-text-container .list-lead,
#events-section .banner-text-container .list-lead,
#funding_purchase-section .banner-text-container .list-lead{
    font-family: Calibri, sans-serif;
    font-size: 15px;
    color: #282828;
    text-align: center;
}
#view-news-section .banner-text-container .list-lead p:last-child,
#news-section .banner-text-container .list-lead p:last-child,
#events-section .banner-text-container .list-lead p:last-child,
#funding_purchase-section .banner-text-container .list-lead p:last-child{
    margin-bottom: 0;
}
#news-section .banner-text-container .list-lead,
#events-section .banner-text-container .list-lead,
#funding_purchase-section .banner-text-container .list-lead{
    max-height: 423px;
    overflow: hidden;
}
#view-news-section .banner-text-container .list-date,
#news-section .banner-text-container .list-date,
#events-section .banner-text-container .list-date,
#funding_purchase-section .banner-text-container .list-date{
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-family: Calibri, sans-serif;
    font-size: 10px;
    color: #5a5a5a;
}
#view-news-section .banner-text-container .list-more,
#news-section .banner-text-container .list-more,
#events-section .banner-text-container .list-more,
#funding_purchase-section .banner-text-container .list-more{
    position: absolute;
    right: 16px;
    bottom: 16px;
    font-family: Calibri, sans-serif;
    font-size: 11px;
    color: #5a5a5a;
    text-decoration: none;
}
#view-news-section .banner-text-container .list-more:hover,
#news-section .banner-text-container .list-more:hover,
#events-section .banner-text-container .list-more:hover,
#funding_purchase-section .banner-text-container .list-more:hover{
    text-decoration: underline;
}
#view-news-section .banner-text-container .list-date::before,
#news-section .banner-text-container .list-date::before,
#events-section .banner-text-container .list-date::before,
#funding_purchase-section .banner-text-container .list-date::before{
    display: inline-block;
    content: "";
    background-image: url(../images/calendar.png);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 11px;
    height: 12px;
    margin-bottom: -2px;
    margin-right: 2px;
}

#news-section img,
#events-section img,
#funding_purchase-section img,
#view-news-section img,
.picture_row img {
    width: 100%;
    border: none;
}

.picture_row img {
    display: block;
}




#view-news-section .heading .banner-text-container .list-title {
  font-size: 200%;
  color: #ccc;
}
#view-news-section .heading .banner-text-container {
  height: auto;
  background-color: #000;
  display: block;
  padding: 40px 10px;
}
a.list-row.heading {
    text-decoration: none;
}



.galeriak {
    position: relative;
}

.site main .main-view.gallery {
    font-size: 0;
    padding-bottom: 80px;
    min-height: 495px;
}

.galeriak div#pager {
    display: block;
    position: absolute;
    top: -140px;
    width: calc(100% + 210px);
    left: -105px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.gallery ul.pagination li {
    top: 340px;
}
.gallery .navigation-numbers {
    top: 165px;
    left: 43px;
    font-size: 14px;
    width: auto;
}

.gallery > div {
    width: 100%;
}
.gallery .content-list-elements {
    display: inline-block;
    width: 25%;
    position: relative;
    padding: 0;
    border: 0 none;
}
.gallery .content-list-elements a {
    display: block;
    height: 186px;
    overflow: hidden;
    text-decoration: none;
    background-color: #000;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    cursor: pointer;
}
.gallery .content-list-elements a .img-thumbnail {
    width: 100%;
    min-height: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.gallery .content-list-elements a:hover,
.gallery .content-list-elements a:focus,
.gallery .content-list-elements a.hover {
    background-color: #f9e805;
    padding: 0 55px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}
.gallery .content-list-elements a.hover h2,
.gallery .content-list-elements a:hover h2,
.gallery .content-list-elements a:focus h2 {
    font-size: 15px;
    display: block;
    position: relative;
    text-align: center;
    font-family: Calibri, Sans-Serif;
    color: #000;
    margin: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transform-origin: center;
            transform-origin: center;
    top: 50%;
    padding: 15px 3px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}
.gallery .content-list-elements a.hover .img-thumbnail,
.gallery .content-list-elements a:hover .img-thumbnail,
.gallery .content-list-elements a:focus .img-thumbnail {    
    display: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.fancybox-inner,
.fancybox-outer {
    position: relative !important;
}


#contact-section .main-view > div {
    width: 100%;
}

#contact-section #mapDiv {
    display: block;
    margin: 60px -105px 0;
    height: 415px;
}
#contact-section .contacts {
    font-size: 0;
}
#contact-section .contacts .icon {
    display: inline-block;
    position: relative;
    font-size: 14px;
    padding-right: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: 40px;
    white-space: normal;
    padding-left: 60px;
    margin-bottom: 20px;
    line-height: 1.5em;
    vertical-align: top;
}
#contact-section .contacts .icon.longer {
    width: 35%;
}
#contact-section .contacts .icon.smaller {
    width: 32%;
}
#contact-section .contacts .icon.fb {
    width: 33%;
    padding: 0;
    display:block;
    float: right;
    margin-bottom: 0;
}
#contact-section .contacts .icon.fb::after {
    display: block;
    content: "";
    clear: both;
}
#contact-section .contacts .icon::before {
    display: block;
    position: absolute;
    left: 0;
    content: "";
    width: 47px;
    height: 47px;
    background-size: 100%;
    background-repeat: no-repeat;
    vertical-align: baseline;
}
#contact-section .contacts .envelope::before {
    background-image: url(../images/mail.jpg);
}
#contact-section .contacts .phone::before {
    background-image: url(../images/phone.jpg);
}
#contact-section .contacts .mail::before {
    background-image: url(../images/e-mail.jpg);
}
#contact-section .contacts .fund::before {
    background-image: url(../images/fund.jpg);
}
#contact-section .contacts .mobile::before {
    background-image: url(../images/mobile.jpg);
}
#contact-section .contacts .icon.fb iframe {
    width: 100%;
}


#contact-section .contacts .more-contacts {
    width: 100%;
    font-size: 14px;
    }


/* Egyéb */

.portlet.StaticContentPortlet.error {
    padding-bottom: 200px;
    padding-top: 40px;
    font-size: 16px;
}

.search1,
.search2 {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
}

.search1 .content-list-elements {
    display: block;
    max-width: 100%;
}

.scrollto-id::before {
    content: '';
    display: block;
    height:      45px;
    margin-top: -45px;
    visibility: hidden;
}

/* NEW */
ul.nav.navbar-nav,
ul.nav.navbar-nav li,
ul.nav.navbar-nav li a{
    box-sizing: border-box;
}

ul.nav.navbar-nav{
    display:flex;
}

nav.navbar ul.nav.navbar-nav > li > a{
    font-size: 13px;
    height: 100%;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
}

ul.nav.navbar-nav li{
    text-align: center;
    width: min-content;
}

ul.nav.navbar-nav li:not(.hidden){
    display: block;
}

ul.nav.navbar-nav li.highlight{
    max-width: 106px;
}

ul.nav.navbar-nav li.highlight a{
    color: red;
}

ul.nav.navbar-nav li.highlight a:hover{
    color: white;
}

.top-nav-collapse.language-select {
    top: 10px;
}

.navbar-collapse{
    max-height: 58px;
}

nav.top-nav-collapse{
    height: 56px;
}
    
/* NEW END */

/* WEBFORM */

/*eform_main.css override*/
.site > header#header-section { 
    display: block;
    flex-flow: row nowrap;
    justify-content: flex-start;
    border-bottom: none;
    box-shadow: none;
    z-index: 2;
}
/*eform_main.css override end*/

.webform-manager {
	 margin: 0;
	 margin-top: 40px !important;
}
.webform-manager .root-content, .webform-manager .formName {
    display: none;
}
 /*.webform-manager .inline-dialog-renderer-content {
    border: 0 none;
    padding: 0;
    box-shadow: unset;
}*/
.webform-manager fieldset, .webform-manager div[data-type="ObjectPanel"], .webform-manager [data-name=rootPanel] [data-type=Panel] .form-group > label {
    margin: 0;
    width: auto;
}
.webform-manager [data-name=rootPanel]{
    border: none;
    padding: 0;
}
.webform-manager [data-name=rootPanel] [data-type=Panel] .form-group > label {
    padding: 0;
}
.webform-manager [data-name=rootPanel] [data-type=ObjectPanel] .form-group[data-type=Checkbox] label, .webform-manager [data-name=rootPanel] [data-type=ObjectPanel] .form-group[data-type=SelectBoxes] label {
    padding: 0;
    width: 100%;
}
.webform-manager input[type="checkbox"], .webform-manager input[type="radio"] {
    display: inline-block;
    border: 0 none;
    vertical-align: baseline;
}
.webform-manager input[type="text"] {
    display: block;
    width: 100%;
}
.webform-manager .has-error input[type="text"] {
    border: 1px solid red !important;
    border-color: red !important;
}
.webform-manager input[type="checkbox"] + span, .webform-manager input[type="radio"] + span {
    display: inline-block;
    width: calc(100% - 35px);
}
.webform-manager textarea {
    display: block;
    resize: none;
    padding: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
}
.webform-manager label.control-label > span {
    color: red;
}
.webform-manager .help-block {
    padding: 0;
}
.webform-manager .help-block ul, .webform-manager .help-block li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.webform-manager .help-block ul:before, .webform-manager .help-block li:before {
    display: none;
}
.webform-manager [data-component-fragment=errorTemplate], .webform-manager .has-error .help-block {
    font-size: 13px;
    padding-top: 3px;
}
.webform-manager .form-text.text-muted p {
    margin: 10px 0;
}
.webform-manager .recaptcha {
    margin: 0;
    justify-content: unset;
}
.webform-manager .formpanel-buttons {
    margin: 30px 0 5px 0;
}
.webform-manager .formpanel-buttons button:first-of-type {
    margin-left: 0;
}

.webform-manager StaticTextField[data-name="lead"]{
    font-weight: bold;
    margin-bottom: 40px;
}

.webform-manager .formpanel-buttons button.positive.au-target{
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px 35px;
    color: #f9e805;
    background-color: #b3b3b3;
    border: none;
    outline: none;
}

.webform-manager .formpanel-buttons button.positive.au-target:hover, .webform-manager .formpanel-buttons button.positive.au-target:focus {
    opacity: 0.85;
    box-shadow: 0 0 0px 1px darkgrey;
}
/* WEBFORM END */

/* SIMPLEPAY FORM */
.simplePay-form{
    max-width: 67.5rem;
    margin: 0 auto;
    margin-top: 40px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 1.3em;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
}

.simplePay-form .form-row + .form-row{
    margin: 20px 0;
}

.simplePay-form .form-row:first-child{
    margin-top: 0;
}

.simplePay-form .form-row:last-child{
    margin-bottom: 0;
}

.simplePay-form .form-row .form-label {
    margin-bottom: 5px;
    display: inline-block;
}

.simplePay-form .form-row .gdprCheckboxLabel{
    cursor: pointer;
}

.simplePay-form .form-row input[type="text"],
.simplePay-form .form-row input[type="email"],
.simplePay-form .form-row select{
    display: block;
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
    background: none;
    border: 1px solid #757575;
    outline: none;
}

.simplePay-form .form-row select{
    -webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	/*background-image: url('imageurl');
	background-position: 98% center;
	background-repeat: no-repeat;*/
	outline: none;
	cursor: pointer;
}

.simplePay-form .form-row select::-ms-expand{
    display: none;
}

.simplePay-form .form-row .select-wrapper {
    position: relative;
}

.simplePay-form .form-row .select-wrapper::after {
    content: "▼";
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    position: absolute;
    pointer-events: none;
}

.simplePay-form .form-row .button-container .btn.payButton{
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 8px 35px;
    color: #f9e805;
    background-color: #b3b3b3;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
}

.simplePay-form .form-row .button-container .btn.payButton:hover, .simplePay-form .form-row .button-container .btn.payButton:focus {
    opacity: 0.85;
    box-shadow: 0 0 0px 1px darkgrey;
}

.simplePay-form .form-row label.checkbox-label{
    cursor: pointer;
    display: inline-block;
}

.simplePay-form .form-row label.checkbox-label input[type="checkbox"]{
    vertical-align: text-bottom;
}

.simplepay-logo{
    overflow: hidden;
    text-align: center;
    margin: 0 auto;
}

.simplepay-logo a{
    display: inline-block;
}

.portlet.SimplePayResultPortlet.simplepay-result .simplepay-result-title h2 {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 27px;
    font-weight: 500;
    padding: 0;
    text-transform: uppercase;
    margin: 20px 0 40px 0;
}

.portlet.SimplePayResultPortlet.simplepay-result .simplepay-result-title h2:after {
    display: block;
    content: "";
    position: absolute;
    bottom: -5px;
    width: 114px;
    border-top: 2px solid #000;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.portlet.SimplePayResultPortlet.simplepay-result {
    padding-bottom: 200px;
    padding-top: 40px;
    font-size: 16px;
    text-align: center;
}

.portlet.SimplePayResultPortlet.simplepay-result .simplepay-result-message .simplapay-result-back .button-container .btn.btn-back{
    margin-top: 0;
    margin-bottom: 30px;
}
/* SIMPLEPAY FORM END */